import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Undetected CS2 Hacks: Counter-Strike 2 Cheats, Aimbot & ESP | Vajoner Menu
			</title>
			<meta name={"description"} content={"The best CS2 menu with no waiting on cs updates! For only $39.99 you get lifetime hacks for Counter Strike 2. No if ands or buts. Vajoner Menu is the last menu you'll need."} />
			<meta property={"og:title"} content={"Undetected CS2 Hacks: Counter-Strike 2 Cheats, Aimbot & ESP | Vajoner Menu"} />
			<meta property={"og:description"} content={"The best CS2 menu with no waiting on cs updates! For only $25 you get lifetime cheats for CS2. No if ands or buts. Vajoner Menu is the last menu you'll need."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/esppic1.png?v=2023-12-11T07:36:38.347Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/icon.png?v=2023-12-11T18:07:50.349Z"} type={"image/x-icon"} />
		</Helmet>
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/image_processing20230810-35-vezfza.webp?v=2023-12-20T06:15:34.042Z) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="360px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					WOOPZ
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Nothing here!
				</Text>
				<Text font="--base">
					You've hit a 404. *fart noise*
				</Text>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6576a43c8d4a0c002027e286"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n#vajonerMenu li {\n  padding:5px;\n}\n\n#vajonerMenu li:first-of-type {\n  padding-left: 0;\n}\n\n#vajonerMenu li:last-of-type {\n  padding-right: 0;\n}\n\n#vajonerMenu li a  {\n  padding: 10px;\n  background-color: #e48716;\n  color: white;\n  text-decoration: none;\n  border: 1px solid #e48716;\n  border-radius: 3px;\n  font-weight: bold;\n}\n\nbody #buyNowBtn a {\n  background-color: transparent;\n  color: #e48716;\n  border-color: #e48716;\n}\n\n#vajonerMenu li a:hover,\n#vajonerMenu li a:focus {\n  background-color: transparent;\n  color: #e48716;\n  border-color: #e48716;\n}\n\n#buyNowBtn a > span {\n  color: white !important;\n}\n\n#hotKeys {\ntext-align:left;\npadding-left:0;\nmargin-top: 15px;\nmargin-left: 13px;\n}\n\n#hotKeys li {\ndisplay:inline-block;\ntext-align:center;\nborder: 1px solid #a78bee;\ncolor: #a78bee;\ntext-transform: uppercase;\nmargin: 0 6px;\npadding: 7px;\nfont-size: 15px;\nborder-radius: 4px;\n}\n\n#hotKeys li:first-of-type {\nborder: 0;\ncolor: white;\ntext-transform: none;\nmargin: 0;\npadding-left: 0;\n}\n\n.infoStep {\nvertical-align: top;\n}"}
			</style>
			<script
				src={"https://www.googletagmanager.com/gtag/js?id=G-JN9S5W2PJ3"}
				type={"text/javascript"}
				async={false}
				referrerpolicy={""}
				place={"endOfHead"}
				rawKey={"65827f589330b6521069b79e"}
			>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-JN9S5W2PJ3');"}
			</script>
			<script src={""} place={"endOfHead"} rawKey={"6582835633824cf334d9e36d"}>
				{"    (function(c,l,a,r,i,t,y){\n        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};\n        t=l.createElement(r);t.async=1;t.src=\"https://www.clarity.ms/tag/\"+i;\n        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);\n    })(window, document, \"clarity\", \"script\", \"k8qaauuui6\");"}
			</script>
		</RawHtml>
	</Theme>;
});